/*
    Custom
 */

 $(document).ready(function() {
    $('.slider').slick({
      slidesToShow: 2,
      centerMode: true,
      variableWidth: true,
      arrows: false
    });

    $('.wishes .form-button').on('mouseover', function(e){
      e.preventDefault();
      $('.checker-list').show();
    });


    $('.checker-list').on('mouseleave', function(e){
      e.preventDefault();
      $(this).hide();
    });

    $('.hour input').on('change', function(e){
      e.preventDefault();
      var val = $(this).val();
      if (val < 0 || val > 23) { $(this).val(0) }
    })

    $('.minutes input').on('change', function(e){
      e.preventDefault();
      var val = $(this).val();
      if (val < 0 || val > 59) { $(this).val(0) }
    })


    $('input#phone, input#phone2').mask('+7 (000) 000-00-00', {placeholder: '+7 (___) ___-__-__'});

    $('.addressTo .form-button').on('click', function(e){
      e.preventDefault();

      var startPoint = $('.addressBetween').length;

      var html = ''

      if (startPoint) {
        html = createField(startPoint+1);
        $('#field'+startPoint).after(html);
      } else {
        html = createField(1);
        $('.addressTo').after(html);
      }

    })

    function createField(id) {
      return  '<div class="form-group addressBetween col-xs-12 col-md-4" id="field'+id+'">'+
          '<label class="visible-md-block visible-lg-block" for="addressBetween">Куда едем?</label>'+
          '<input type="text" class="form-control col-xs-12" id="addressBetween'+id+'" placeholder="">'+
        '</div>'
    }


    /*time picker*/
    $('.hour-plus').on('click', function(e){
      e.preventDefault();
      var i = $('.input-hour').val();
      i++; if (i>23) {i=0}
      $('.input-hour').val(i);
    })

    $('.hour-minus').on('click', function(e){
      e.preventDefault();
      var i = $('.input-hour').val();
      i=i-1; if (i<0) {i=23}
      $('.input-hour').val(i);
    })

    $('.minutes-plus').on('click', function(e){
      e.preventDefault();
      var i = $('.input-min').val();
      i++; if (i>59) {i=0}
      $('.input-min').val(i);
    })

    $('.minutes-minus').on('click', function(e){
      e.preventDefault();
      var i = $('.input-min').val();
      i=i-1; if (i<0) {i=59}
      $('.input-min').val(i);
    })

    $('.button-big--green').on('click', function(e){
      e.preventDefault();
      var hour = $('.input-hour').val(), minutes = $('.input-min').val();
      (hour.length==1)?hour='0'+hour:hour=hour;
      (minutes.length==1)?minutes='0'+minutes:minutes=minutes;
      var result = $('#needtime').val() + ' ' + hour + ':' + minutes; 
      $('#time').val(result);
      $('.datepicker').hide();
    })

    $('.time .form-button').on('click', function(e){
      e.preventDefault();
      $('.datepicker').show();
    })

 });